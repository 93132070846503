import React from 'react';
import loadable from '@loadable/component';
import { Props as CtaOverlayProps } from '.';

const LazyLoadedCtaOverlay = loadable(() => import('components/CtaOverlay'));

export type Props = CtaOverlayProps;

const CtaOverlayLazyLoader:React.FC<Props> = (props) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? <LazyLoadedCtaOverlay {...props} /> : null;
};
export default CtaOverlayLazyLoader;
