import { BreakpointsBasic } from 'styled';
import useCssBreakpointIndexes from './useCssBreakpointIndexes';

/**
 * Returns true for any breakpoint LARGER THAN, AND INCLUDING queryBreakpointName
 */
const useFromBreakpoint = (
  queryBreakpointName: keyof BreakpointsBasic,
): boolean => {
  const { current, queried } = useCssBreakpointIndexes(queryBreakpointName);
  return current >= queried;
};

export default useFromBreakpoint;
