import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import wordpressGallery from './wordpressGallery';
import userContent from './userContent';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }
  body {
    font-size: 1.6rem;
    font-family: ${theme.type.font1};
    color: ${theme.palette.text};
  }
  h1, h2, h3 {
    /* -webkit-hyphens: auto;
   hyphens: auto;
   word-break: break-word; */
  }

  a {
    /* color: ${theme.palette.primary}; */
    color: inherit;
  }

  strong {
    font-weight: 600;
  }

  .no-margin {
    margin: 0 !important;
  }

  ${wordpressGallery};
  ${userContent};
`;

export default GlobalStyle;
