/**
 * @return Returns a Promise wrapped base64 string with header removed
 */
export const toBase64 = (file: Blob): Promise<string> => new Promise((resolve, reject) => {
  const removeHeader = (input: string): string => input.replace(/^data:.*,/, '');
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      const resultClean = removeHeader(reader.result);
      resolve(resultClean);
    } else {
      reject(new Error('invalid response from FileReader instance'));
    }
  };
  reader.onerror = (error) => reject(error);
});
