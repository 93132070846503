import { css } from 'styled-components';

export default css`
  .wp-block-columns {
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      display: flex;
      margin: 1em -10px;
      h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
      }
      &.are-vertically-aligned-center {
        align-items: center;
      }
    }

    .wp-block-column {
      margin-top: 30px;
      ${({ theme }) => theme.breakpoints.up('tablet')} {
        flex: 1 1 auto;
        max-width: 100%;
        padding: 0 10px;
        margin-top: 0;
      }
    }
  }
`;
