import React from 'react';
import { variantComponents } from './variantComponents';
import { Variants } from './types';


export interface Props {
  [propName: string]: any,
  variant?: Variants,
  as?: React.ElementType,
  bold?: true,
  italic?: true,
  color?: React.CSSProperties['color'],
  maxWidth?: React.CSSProperties['maxWidth'],
  className?: string,
}

const Typography:React.FC<Props> = ({
  variant = 'body',
  children,
  as: asProp,
  color,
  maxWidth,
  className,
  ...restProps
}) => {
  const Element = variantComponents[variant];
  return (
    <Element
      as={asProp as any} // eslint-disable-line
      $color={color}
      $maxWidth={maxWidth}
      className={className}
      {...restProps}
    >
      {children}
    </Element>
  );
};
export default Typography;
