import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.FooterSettingsSingleFragment | Record<string, never> => {
  const result = useStaticQuery<GatsbyTypes.FooterSettingsQuery>(graphql`
    fragment FooterSettingsSingle on WpFooterSettings_Acf {
      smallprintPages {
        ... on WpPage {
          id
          title
          slug
        }
      }
      quicknavLinks {
        quicknavLink {
          target
          title
          url
        } 
      }
    }
    query FooterSettings {
      allWp {
        nodes {
          footerSettings {
            acf {
              ...FooterSettingsSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].footerSettings?.acf || {};
};
