import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from 'store';

interface State {
  isVisible: boolean,
}

const initialState: State = {
  isVisible: false,
};

/* eslint-disable no-param-reassign */
export const slice = createSlice({
  name: 'ctaOverlay',
  initialState,
  reducers: {
    toggleVisibility: (state) => {
      state.isVisible = !state.isVisible;
    },
    setVisibility(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const { actions, reducer } = slice;
export const { toggleVisibility, setVisibility } = actions;
export default reducer;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
