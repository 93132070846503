// re-written in node for gatsby-node interoperability

const trimSlashes = require('./trimSlashes');

module.exports = (
  href,
  baseUrl = '',
  isClient = true,
) => {
  const isTruthyAndNotSlash = (input) => (Boolean(input) && input !== '/');
  const hrefCleanString = href
    .filter(isTruthyAndNotSlash)
    .map((input) => trimSlashes(input))
    .join('/');

  try {
    return new URL(hrefCleanString, baseUrl).toString();
  } catch (error) {
    const conditionalSlash = isClient ? '/' : '';
    return conditionalSlash + hrefCleanString + conditionalSlash;
  }
};
