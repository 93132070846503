import React from 'react';
import Container, { Props as ContainerProps } from 'components/Container';


export type Props = ContainerProps & { show: boolean };

const ConditionalContainer: React.FC<Props> = ({
  show,
  children,
  ...restProps
}) => {
  if (show) return <Container {...restProps}>{children}</Container>;
  else return <>{children}</>;
};
export default ConditionalContainer;
