import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.GeneralSettingsSingleFragment | Record<string, never> => {
  const result = useStaticQuery<GatsbyTypes.GeneralSettingsQuery>(graphql`
    fragment GeneralSettingsSingle on WpGeneralSettings {
      title
      description
      url
    }
    query GeneralSettings {
      allWp {
        nodes {
          generalSettings {
            ...GeneralSettingsSingle
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].generalSettings || {};
};
