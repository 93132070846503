/**
 * Removes html tags using regex. THIS IS NOT GUARANTEED TO BE SECURE
 * @param {string} input - string containing html tags
 * @returns {string} string with html tags removed
 */
export default (input: string):string => (
  input
    .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
    .replace(/<[^>]*>?/gm, '')
);
