import { css } from 'styled-components';
import theme from '../theme';

export default css`
  .figure.wp-block-media-text__media {
    text-align: center;
  }
  ${theme.breakpoints.up('tablet')} {
    .wp-block-media-text {
      display: flex;
      &.is-vertically-aligned-center {
        align-items: center;
      }
      &.is-vertically-aligned-bottom {
        align-items: flex-end;
      }
      &.has-media-on-the-right {
        figure.wp-block-media-text__media {
          order: 1;
        }
      }
      figure.wp-block-media-text__media {
        max-width: 100%;
        width: 40%;
        flex: 0 0 auto;
        > span {
          width: 100% !important;
        }
      }
      .wp-block-media-text__content {
        max-width: 100%;
        flex: 1 1 auto;
      }
    }
  }
`;
