import React from 'react';
import styled, { css } from 'styled-components';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  icon: IconDefinition,
  borderColor?: React.CSSProperties['color'],
  background?: React.CSSProperties['background'],
  color?: React.CSSProperties['color'],
  size?: React.CSSProperties['fontSize'],
}

const SmallIcon:React.FC<Props> = ({
  icon,
  borderColor,
  background,
  color,
  size = '1.6rem',
}) => {
  return (
    <SmallIconOuter
      borderColor={borderColor}
      background={background}
      color={color}
      size={size}
    >
      <FontAwesomeIcon icon={icon} />
    </SmallIconOuter>
  );
};
export default SmallIcon;

const SmallIconOuter = styled.div<Pick<Props, 'borderColor' | 'background' | 'color' | 'size'>>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: ${(p) => p.size};
  width: 2em;
  height: 2em;
  padding: 0.4em;
  background: ${(p) => p.background};
  color: ${(p) => p.color};
  border-radius: 50%;
  ${(p) => p.borderColor && css`
    border: 1px solid ${p.borderColor};
  `};
`;
