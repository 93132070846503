import { css } from 'styled-components';
import general from './general';
import quotes from './quotes';
import tables from './tables';
import images from './images';
import columns from './columns';
import gallery from './gallery';
import mediaAndText from './mediaAndText';
import buttons from './buttons';
import headings from './headings';

export default css`
  .user-content {
    ${general};
    ${quotes};
    ${tables};
    ${images};
    ${columns};
    ${gallery};
    ${mediaAndText};
    ${buttons};
    ${headings};
  }
`;
