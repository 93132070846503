import React from 'react';
import Spacer from 'components/Spacer';
import useFromBreakpoint from 'hooks/useFromBreakpoint';
import styled from 'styled-components';
import ConditionalContainer from 'components/ConditionalContainer';
import { Props as ContainerProps } from 'components/Container';
import Typography from './Typography';

type SpacerProps = Parameters<typeof Spacer>[0];

type ValidConfigTypes = 'default' | 'noPadding';
interface CommonProps {
  id?: string,
  type?: ValidConfigTypes,
  customConfig?: SpacerProps,
  background?: React.CSSProperties['background'],
  className?: string,
  hideOverflow?: boolean,
  title?: string,
  smallTitle?: boolean,
  secondaryTitle?: string,
  contain?: boolean,
  containerConfig?: Partial<ContainerProps>,
  readableWidth?: boolean,
  flexHeight?: true,
  lineBorders?: boolean,
}

interface DarkBgProps {
  darkBg?: boolean,
  highlightBg?: never;
}

interface HighlightBgProps {
  darkBg?: never,
  highlightBg?: boolean;
}

export type Props = (
  | CommonProps & DarkBgProps
  | CommonProps & HighlightBgProps
);


const Block:React.FC<Props> = ({
  children,
  id,
  type = 'default',
  customConfig = {},
  darkBg,
  highlightBg,
  background,
  className,
  hideOverflow = false,
  title = '',
  smallTitle = false,
  secondaryTitle = '',
  contain = false,
  containerConfig = {
    yPadding: 0,
  },
  readableWidth = false,
  flexHeight,
  lineBorders = false,
}) => {
  const fromPhablet = useFromBreakpoint('phablet');
  const config: { [key in ValidConfigTypes]: SpacerProps } = {
    default: {
      py: fromPhablet ? 3 : 2,
      multiplier: 20,
    },
    noPadding: {},
  };
  const activeConfigObj = {
    ...config[type],
    ...customConfig,
  };

  return (
    <SpacerStyled
      {...activeConfigObj}
      id={id}
      className={[
        className,
        ...(darkBg ? ['dark-bg'] : []),
        ...(highlightBg ? ['highlight-bg'] : []),
        ...(hideOverflow ? ['hide-overflow'] : []),
        ...(flexHeight ? ['flex-height'] : []),
        ...(lineBorders ? ['line-borders'] : []),
        ...(secondaryTitle ? ['has-secondary-title'] : []),
      ].join(' ')}
      $background={background}
      as={undefined}
    >
      <ConditionalContainer
        show={contain}
        positionRelativeInner
        readableWidth={readableWidth}
        flexHeight={flexHeight}
        {...containerConfig}
        lineBorders={lineBorders}
      >
        {title && (
          <Spacer mb={3}>
            <Typography variant={smallTitle ? 'h2' : 'hero'} as="h3">{title}</Typography>
          </Spacer>
        )}
        {secondaryTitle && (
          <SecondaryTitle>
            <TypographyStyled variant="small">{secondaryTitle}</TypographyStyled>
          </SecondaryTitle>
        )}
        {children}
      </ConditionalContainer>
    </SpacerStyled>
  );
};
export default Block;

const SpacerStyled = styled(Spacer)<{ $background: Props['background'] }>`
  position: relative;
  background: ${(p) => p.$background};
  &.dark-bg {
    background-color: ${(p) => p.theme.palette.primary};
    color: ${(p) => p.theme.palette.darkBgText};
  }
  &.highlight-bg {
    background-color: ${(p) => p.theme.palette.secondary};
    color: ${(p) => p.theme.palette.primary};
  }
  &.hide-overflow {
    overflow: hidden;
  }
  &.flex-height {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
`;

const SecondaryTitle = styled.div`
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    margin-bottom: 0;
    position: absolute;
    left: 20px;
    top: 50%;
    width: 15em;
    height: 20px;
    transform: translateY(-50%);
  }
`;
const TypographyStyled = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%) rotate(-90deg);
    transform: translateX(calc(-50% + 10px)) rotate(-90deg);
    text-align: center;
  }
  ${SpacerStyled}.dark-bg & {
    color: ${(p) => p.theme.palette.secondary};
  }
`;
