import React from 'react';
import styled, { css } from 'styled-components';

export interface Props {
  className?: string,
  style?: React.CSSProperties,
  xPadding?: number,
  yPadding?: number,
  fluid?: boolean,
  width?: React.CSSProperties['width'],
  marginAuto?: boolean,
  background?: true | React.CSSProperties['color'],
  flexHeight?: true,
  vCenter?: true,
  as?: React.ElementType,
  positionRelativeOuter?: boolean,
  positionRelativeInner?: boolean,
  readableWidth?: boolean,
  shift?: boolean,
  lineBorders?: boolean,
}


const Container:React.FC<Props> = ({
  className,
  style,
  children,
  xPadding = 30,
  yPadding = 30,
  fluid = false,
  width = '1400px',
  marginAuto = true,
  as,
  background,
  flexHeight,
  vCenter,
  positionRelativeOuter = true,
  positionRelativeInner = false,
  readableWidth = false,
  shift = false,
  lineBorders = false,
}) => {
  const ConditionalReadableWidthContainer = readableWidth
    ? ReadableWidthContainer
    : React.Fragment;
  return (
    <ContainerOuter
      className={[
        className,
        ...(flexHeight ? ['flexHeight'] : []),
        ...(vCenter ? ['vCenter'] : []),
        ...(positionRelativeOuter ? ['positionRelative'] : []),
        ...(shift ? ['shift'] : []),
        ...(lineBorders ? ['line-borders'] : []),
      ].join(' ')}
      style={style}
      as={as}
      background={background}
    >
      <Inner
        xPadding={xPadding}
        yPadding={yPadding}
        fluid={fluid}
        theWidth={width}
        marginAuto={marginAuto}
        positionRelative={positionRelativeInner}
        readableWidth={readableWidth}
      >
        <ConditionalReadableWidthContainer>
          {children}
        </ConditionalReadableWidthContainer>
      </Inner>
    </ContainerOuter>
  );
};
export default Container;


interface ContainerOuterProps {
  background: Props['background'],
  flexHeight: Props['flexHeight'],
  vCenter: Props['vCenter'],
  positionRelative: Props['positionRelativeOuter'],
  shift: Props['shift'],
}

const ContainerOuter = styled.div<ContainerOuterProps>`
  max-width: 100%;
  ${({ background, theme }) => {
    let backgroundColor: React.CSSProperties['color'] = '';
    let textColor: React.CSSProperties['color'] = '';
    if (background === true) {
      backgroundColor = theme.palette.primary;
      textColor = 'white';
    } else if (typeof background === 'string') {
      backgroundColor = background;
    }
    return css`
      background-color: ${backgroundColor};
      color: ${textColor};
    `;
  }};
  &.flexHeight {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    ${(p) => p.vCenter && css`
      justify-content: center;
      align-items: flex-start;
    `}
  }
  &.vCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.positionRelative {
    position: relative;
  }
  &.shift {
    ${(p) => p.theme.breakpoints.up('tablet')} {
      padding-left: ${(p) => p.theme.misc.indentShiftAmount};
    }
  }
`;

interface InnerProps {
  fluid?: boolean,
  theWidth: Props['width'],
  xPadding: number,
  yPadding: number,
  marginAuto: boolean,
  positionRelative: Props['positionRelativeInner'],
  readableWidth: Props['readableWidth'],
}
const Inner = styled.div<InnerProps>`
  position: ${(p) => (p.positionRelative ? 'relative' : undefined)};
  box-sizing: border-box;
  width: ${(p) => (p.fluid ? '100%' : p.theWidth)};
  max-width: 100%;
  margin: ${(p) => (p.marginAuto ? '0 auto' : '0')};
  padding: ${(p) => `${p.yPadding}px ${p.xPadding}px`};
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    padding: ${(p) => `${p.yPadding * 2}px ${p.xPadding * 2}px`};
  }
  ${ContainerOuter}.flexHeight & {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  ${ContainerOuter}.line-borders > & {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 30%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${(p) => p.theme.palette.primary};
      .dark-bg & {
        background-color: ${(p) => p.theme.palette.secondary};
      }
    }
    &.has-secondary-title:before {
      display: none;
    }
    &:before {
      left: 14px;
      ${({ theme }) => theme.breakpoints.up('tablet')} {
        left: 29px;
      }
    }
    &:after {
      right: 14px;
      ${({ theme }) => theme.breakpoints.up('tablet')} {
        right: 29px;
      }
    }
  }
`;

const ReadableWidthContainer = styled.div`
  width: ${(p) => p.theme.misc.readibleWidth};
  max-width: 100%;
`;
