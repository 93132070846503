export const scrollToPageTop = (duration = 300): Promise<void> => new Promise((res) => {
  const scrollElement = document.documentElement || document.body;
  if (scrollElement.scrollTop === 0) res();

  const cosParameter = scrollElement.scrollTop / 2;
  let scrollCount = 0;
  let oldTimestamp: number | null = null;

  function step(newTimestamp: number) {
    if (oldTimestamp !== null) {
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) {
        scrollElement.scrollTop = 0;
        res();
      }
      scrollElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
    }

    if (scrollElement.scrollTop !== 0) {
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    } else {
      res();
    }
  }
  window.requestAnimationFrame(step);
});
