exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-career-tsx": () => import("./../../../src/templates/career.tsx" /* webpackChunkName: "component---src-templates-career-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-case-studies-tsx": () => import("./../../../src/templates/case-studies.tsx" /* webpackChunkName: "component---src-templates-case-studies-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-industries-tsx": () => import("./../../../src/templates/industries.tsx" /* webpackChunkName: "component---src-templates-industries-tsx" */),
  "component---src-templates-industry-service-tsx": () => import("./../../../src/templates/industry-service.tsx" /* webpackChunkName: "component---src-templates-industry-service-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-sub-service-tsx": () => import("./../../../src/templates/sub-service.tsx" /* webpackChunkName: "component---src-templates-sub-service-tsx" */)
}

