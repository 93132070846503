import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { Link } from 'gatsby';
// import { mix } from 'polished';

export interface PropsCommon {
  className?: string,
  borderless?: boolean,
  background?: React.CSSProperties['color'],
  type?: 'primary' | 'secondary' | 'clear',
  variant?: 'normal' | 'ghost' | 'underline',
  typeAttribute?: React.ButtonHTMLAttributes<'button'>['type'],
  size?: 'large' | 'small',
  circle?: boolean,
  circleSize?: React.CSSProperties['width'];
  ariaLabel?: string,
}
export interface PropsAnchor extends PropsCommon {
  onClick?: never,
  href: string,
  target?: string,
  rel?: string,
  disabled?: never,
}
export interface PropsButton extends PropsCommon {
  href?: never,
  target?: never,
  rel?: never,
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  disabled?: boolean,
}
export type Props = PropsButton | PropsAnchor;

const Button:React.FC<Props> = ({
  children,
  borderless = false,
  background = 'white',
  onClick,
  href,
  target,
  rel,
  disabled,
  className,
  type = 'primary',
  variant = 'normal',
  typeAttribute,
  size,
  circle = false,
  circleSize = '2em',
  ariaLabel,
}) => {
  const httpOrhttpsRegex = /^https?:\/\//;
  const isExternalLink = !!(href) && !!(httpOrhttpsRegex.exec(href));
  let config: {
    as: React.ElementType,
    href?: string,
    to?: string,
    target?: string,
    rel?: string,
    type?: React.ButtonHTMLAttributes<'button'>['type'],
    onClick?: PropsButton['onClick'],
    disabled?: boolean,
  };
  if (href) {
    config = {
      as: (isExternalLink ? 'a' : Link),
      [isExternalLink ? 'href' : 'to']: href,
      target,
      rel: target === '_blank' ? (rel || 'noopener noreferrer') : rel,
    };
  } else {
    config = {
      as: 'button',
      type: typeAttribute || 'button',
      onClick,
      disabled,
    };
  }

  return (
    <ButtonElement
      className={classnames([
        className,
        `type-${type}`,
        `variant-${variant}`,
        { borderless },
        { circle },
        ...(size ? [`size-${size}`] : []),
      ])}
      aria-label={ariaLabel}
      $background={background}
      $circleSize={circleSize}
      {...config}
    >
      {children}
    </ButtonElement>
  );
};
export default Button;

/* eslint-disable @typescript-eslint/indent */
interface GetUnderlineCssArgs {
  backgroundColor: React.CSSProperties['backgroundColor'],
  height?: React.CSSProperties['height'],
}
const getUnderlineCss = ({
  backgroundColor,
  height = '2px',
}: GetUnderlineCssArgs) => css`
  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    bottom: 0.25em;
    left: 0;
    right: 0;
    /* margin: 0 0.5em; */
    height: ${height};
    background-color: ${backgroundColor};
    .highlight-bg & {
      opacity: 0.8;
    }
  }
`;

export const buttonCss = css<{ $background: Props['background'], $circleSize: Props['circleSize'] }>`
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${(p) => p.$background};
  /* color: ${(p) => p.theme.palette.primary}; */
  font-family: ${(p) => p.theme.type.font1};
  font-weight: 600;
  color: inherit;
  border: solid 1px;
  border-radius: 2em;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  &.borderless { border: none; }
  &.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: ${(p) => p.$circleSize};
    width: ${(p) => p.$circleSize};
    padding: 0;
    text-align: center;
    border-radius: 100%;
  }
  &.type {
    &-primary {
      background: ${(p) => p.theme.palette.interactive};
      color: ${(p) => p.theme.palette.primary};
      border-color: ${(p) => p.theme.palette.interactive};
      .highlight-bg & {
        border: 3px solid ${(p) => p.theme.palette.primary};
      }
    }
    &-secondary {
      background: ${(p) => p.theme.palette.secondary};
      color: ${(p) => p.theme.palette.primary};
      border-color: ${(p) => p.theme.palette.secondary};
      .highlight-bg & {
        background: ${(p) => p.theme.palette.primary};
        color: ${(p) => p.theme.palette.secondary};
        border-color: ${(p) => p.theme.palette.primary};
      }
    }
    &-clear {
      background-color: transparent;
    }
  }
  &.variant {
    &-ghost {
      position: relative;
      background-color: transparent;
      border-radius: 0;
      border-width: 2px;
      overflow: hidden;
      transition: color 0.3s, transform 0.2s;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        transition: transform 0.15s ease-in-out;
        z-index: -1;
      }
      &:hover {
        &:before {
          transform: translateX(0);
        }
      }
      &.type-primary {
        &&:hover {
          color: ${(p) => p.theme.palette.primary};
        }
        &:before {
          background-color: ${(p) => p.theme.palette.interactive};
        }
        .dark-bg & {
          color: ${(p) => p.theme.palette.interactive};
        }
        .highlight-bg & {
          border-color: ${(p) => p.theme.palette.interactive};
        }
      }
      &.type-secondary {
        .dark-bg & {
          color: ${(p) => p.theme.palette.secondary};
        }
        .highlight-bg & {
          color: ${(p) => p.theme.palette.primary};
          background-color: transparent;
        }
      }
    }
    &-underline {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      background: transparent !important;
      border: none !important;
      &.type-primary {
        ${(p) => (getUnderlineCss({ backgroundColor: p.theme.palette.interactive }))};
        .dark-bg & {
          color: ${(p) => p.theme.palette.interactive};
        }
      }
      &.type-secondary {
        background-color: transparent;
        ${(p) => (getUnderlineCss({ backgroundColor: p.theme.palette.secondary }))};
        .dark-bg & {
          color: ${(p) => p.theme.palette.secondary};
        }
        .highlight-bg & {
          color: ${(p) => p.theme.palette.primary};
          ${(p) => (getUnderlineCss({ backgroundColor: p.theme.palette.primary }))};
        }
      }
    }
  }
  &.size {
    &-large {
      font-size: 1.2em;
    }
    &-small {
      font-size: 0.8em;
    }
  }
  &[disabled] {
    cursor: default;
    opacity: 0.5;
    &:hover {
      transform: none;
    }
  }
`;

const ButtonElement = styled.button`
  ${buttonCss};
`;
/* eslint-enable @typescript-eslint/indent */
