import { BreakpointsBasic } from 'styled';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type BreakpointName = keyof BreakpointsBasic | null;

interface State {
  cssBreakpointName: BreakpointName,
  scrollingLocked: boolean, // todo
}

const initialState: State = {
  cssBreakpointName: null,
  scrollingLocked: false,
};

/* eslint-disable no-param-reassign */
export const slice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    setCssBreakpointName(state, action: PayloadAction<BreakpointName>) {
      state.cssBreakpointName = action.payload;
    },
    setScrollingLocked(state, action: PayloadAction<boolean>) {
      state.scrollingLocked = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const { actions, reducer } = slice;
export const { setCssBreakpointName, setScrollingLocked } = actions;
export default reducer;
