export default class TelephoneNumber {
  constructor(
    public phoneNumber: string,
    public altDigitSpacing = false,
  ) {}

  get cleanDigits(): string {
    return this.phoneNumber.replace(/\D/g, '');
  }

  get international(): string {
    return this.cleanDigits.replace(/^0/, '+44');
  }

  get domestic(): string {
    return this.cleanDigits;
  }

  get internationalFormatted(): string | null {
    const search = this.altDigitSpacing
      ? /^(\+44)(\d{3})(\d{3})(\d{4})$/
      : /^(\+44)(\d{4})(\d{3})(\d{3})$/;
    const match = search.exec(this.international) || [];
    if (!match) return null;

    const [, group1, group2, group3, group4] = match;
    return `${group1} (0) ${group2} ${group3} ${group4}`;
  }

  get domesticFormatted(): string | null {
    const search = this.altDigitSpacing
      ? /^(\d{4})(\d{3})(\d{4})$/
      : /^(\d{5})(\d{3})(\d{3})$/;
    const match = search.exec(this.domestic) || [];
    if (!match) return null;

    const [, group1, group2, group3] = match;
    return `${group1} ${group2} ${group3}`;
  }
}
