import { breakpointsOrdered } from 'src/styles/theme';
import { BreakpointsBasic } from 'styled';
import { useAppSelector } from 'store/hooks';

function getIndex(breakpointName: keyof BreakpointsBasic | null): number {
  return breakpointsOrdered.findIndex((point) => (point.key === breakpointName));
}

interface CssBreakpointIndexes {
  current: number,
  queried: number,
}

const useCssBreakpointIndexes = (
  queryBreakpointName: keyof BreakpointsBasic,
): CssBreakpointIndexes => {
  const currentBreakpointName = useAppSelector((store) => store.utility.cssBreakpointName);
  const queryBreakpointIndex = getIndex(queryBreakpointName);
  const currentBreakpointIndex = getIndex(currentBreakpointName);
  return {
    current: currentBreakpointIndex,
    queried: queryBreakpointIndex,
  };
};

export default useCssBreakpointIndexes;
