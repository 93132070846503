import { css } from 'styled-components';

export default css`
  figure.wp-block-table {
    margin-top: 0;
    margin-bottom: 2em;
    table {
      width: 100%;
      border-collapse: collapse;
      &.has-fixed-layout {
        table-layout: fixed;
      }
      thead {
        th {
          text-align: left;
        }
      }
      tfoot {
        tr {
          border-top: solid 1px lightgrey;
        }
      }
      tr {
        td, th {
          border-bottom: solid 1px lightgrey;
          &.has-text-align-center {
            text-align: center;
          }
        }
      }
      tr:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
    figcaption {
      margin-top: 10px;
      font-size: 1.2rem;
      font-style: italic;
    }
    &.is-style-stripes {
      table {
        tr:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, 0.05);
        }
        thead, tfoot {
          tr:nth-of-type(odd) {
            background-color: transparent;
          }
        }
      }
    }
  }
`;
