import { css } from 'styled-components';

export default css`
  p {
    margin-top: 1em;
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin-bottom: 2em;
    li {
      margin-bottom: 0.6em;
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding-left: 3em;
    > li {
      counter-increment: item;
      text-indent: -1.9em;
      li {
        text-indent: 0;
      }
      &:before {
        content: counter(item) ".";
        display: inline-block;
        text-align: center;
        width: 1.2em;
        padding-right: 0.7em;
        font-weight: bold;
        text-align: right;
      }
    }
  }

  pre.wp-block-code {
    padding: 10px;
    overflow: scroll;
    font-size: 1.4rem;
    background-color: ${(p) => p.theme.palette.text};
    color: #9bb7d5;
    border-radius: 5px;
  }

  .wp-block-separator {
    width: 50%;
    margin: 40px auto;
    border: 1px solid ${(p) => p.theme.palette.text};
    opacity: 0.2;
  }

  figcaption {
    font-style: italic;
  }

  .wp-block-embed {
    iframe {
      max-width: 100%;
    }
  }

  .has-text-align-left {
    text-align: left;
  }
  .has-text-align-center {
    text-align: center;
  }
  .has-text-align-right {
    text-align: right;
  }
  .is-content-justification-center {
    justify-content: center;
  }
`;
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
