import React from 'react';
import styled from 'styled-components';

interface Props {
  viewBox: string,
  width?: number,
  height?: number,
  className?: string,
}

const Svg:React.FC<Props> = ({
  viewBox,
  width,
  height,
  className,
  children,
}) => (
  <SvgComponent
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    {children}
  </SvgComponent>
);
export default Svg;

const SvgComponent = styled.svg``;
