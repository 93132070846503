import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Wordmark from './Wordmark';
import Logo from './Logo';

interface Props {
  withHomeLink?: boolean,
  hideLogo?: boolean,
  // color?: React.CSSProperties['fill'],
}

const CompanyLogo:React.FC<Props> = ({
  withHomeLink = false,
  hideLogo = false,
  // color,
}) => {
  const ConditionalLink = withHomeLink
    ? (p: { children: React.ReactNode }) => <LinkStyled to="/">{p.children}</LinkStyled>
    : React.Fragment;
  return (
    <CompanyLogoOuter>
      <ConditionalLink>
        <ElementsWrap>
          {!hideLogo && (
            <LogoWrap>
              <Logo />
            </LogoWrap>
          )}
          <WordmarkCreditWrap>
            <Wordmark />
            <XlMedia>from XLMedia</XlMedia>
          </WordmarkCreditWrap>
        </ElementsWrap>
      </ConditionalLink>
    </CompanyLogoOuter>
  );
};
export default CompanyLogo;

const CompanyLogoOuter = styled.div`
  position: relative;
`;
const LinkStyled = styled(Link)`
  display: block;
  line-height: 0;
  text-decoration: none;
`;
const XlMedia = styled.div`
  margin-top: 0.2em;
  font-size: 1.2rem;
  line-height: 1em;
  color: ${(p) => p.theme.palette.primary};
  .dark-bg & { color: white; }
`;
const LogoWrap = styled.div`
  width: 36px;
  margin-right: 10px;
`;
const ElementsWrap = styled.div`
  display: flex;
`;
const WordmarkCreditWrap = styled.div`
  width: 150px;
`;
