import { css } from 'styled-components';

const flexHeightCss = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export default css`
  .gallery {
    margin: 0 -10px;
    padding: 4rem 0;
    .gallery-item {
      ${flexHeightCss};
      box-sizing: border-box;
      padding: 10px;
      margin: 0;
      .gallery-icon {
        ${flexHeightCss};
        > span {
          ${flexHeightCss};
          width: 100% !important;
        }
      }
    }
    &.gallery-columns-2 {
      display: flex;
    }
  }
`;
