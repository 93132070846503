import React from 'react';

interface Props {
  isLocked: boolean,
}

const ScrollLocker:React.FC<Props> = ({ isLocked }) => {
  const [initialScrollPos, setInitialScrollPos] = React.useState(0);
  React.useEffect(() => {
    const { body } = document;
    const { scrollY } = window;
    setInitialScrollPos(scrollY);
    if (isLocked) {
      body.style.top = `-${scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
      document.body.style.width = '100%';
    } else {
      body.style.top = '';
      document.body.style.position = '';
      document.body.style.overflowY = 'visible';
      document.body.style.width = '';
      window.scrollTo(0, initialScrollPos);
    }
  }, [isLocked]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
export default ScrollLocker;
