import { DefaultTheme } from 'styled-components';
import { BreakpointsBasic } from 'styled';

class BreakpointOrdered {
  constructor(
    public key: keyof BreakpointsBasic,
    public rawVal: number,
  ) {}

  get val() {
    return `${this.rawVal}px`;
  }
}

export const breakpointsOrdered: BreakpointOrdered[] = [
  new BreakpointOrdered('phone', 400),
  new BreakpointOrdered('phoneWide', 480),
  new BreakpointOrdered('phablet', 560),
  new BreakpointOrdered('tabletSmall', 640),
  new BreakpointOrdered('tablet', 768),
  new BreakpointOrdered('tabletWide', 1024),
  new BreakpointOrdered('desktop', 1248),
  new BreakpointOrdered('desktopWide', 1440),
];

export const breakpointsBasic: BreakpointsBasic = {
  ...breakpointsOrdered.reduce((acc, { key, val }) => ({
    ...acc,
    [key]: val,
  }), {} as BreakpointsBasic),
};

export type BreakpointKey = keyof typeof breakpointsBasic;

const breakpoints: DefaultTheme['breakpoints'] = {
  ...breakpointsBasic,
  up: (key) => `@media screen and (min-width: ${breakpoints[key]})`,
  down: (key) => `@media screen and (max-width: ${Number.parseInt(breakpoints[key], 10) - 1}px)`,
};
export default breakpoints;

