import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  isVisible: boolean,
}

const initialState: State = {
  isVisible: false,
};

/* eslint-disable no-param-reassign */
export const slice = createSlice({
  name: 'navMain',
  initialState,
  reducers: {
    setVisibility(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const { actions, reducer } = slice;
export const { setVisibility } = actions;
export default reducer;
