/**
 * Breaks sentence after the character limit is reached, only cutting after the full stop
 * @param {string} text - the uncapped input
 * @param {number} characterLimit - the number of characters to allow before looking for exit
 * @returns {string} the output string, capped after the last full stop over the character limit
 */
export default (text: string, characterLimit = 200): string => {
  const breakPatterns = [
    '. ',
    '.<',
    '\n\n',
  ];
  let textCapped = '';
  for (let i = 0; i < text.length; i += 1) {
    const character = text[i];
    const lastTwoCharacters = text[i - 1] + text[i - 2];
    const limitExceeded = i > characterLimit;
    if (limitExceeded && breakPatterns.includes(lastTwoCharacters)) break; // end with the sentence
    else textCapped += character;
  }
  return textCapped;
};
