import React from 'react';
import CtaOverlayLazyLoaded from 'src/components/CtaOverlay/LazyLoader';
import Footer from 'components/Footer';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setCssBreakpointName } from 'store/features/utility/utilitySlice';
import useActiveBreakpoint from 'src/hooks/useActiveBreakpoint';
import ScrollLocker from 'lib/components/ScrollLocker';

// import SiteLoader from 'components/SiteLoader';

interface Props {}

const Layout:React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const breakpoint = useActiveBreakpoint();
  const ctaOverlayIsVisible = useAppSelector((state) => state.ctaOverlay.isVisible);
  const scrollingLocked = useAppSelector((state) => state.utility.scrollingLocked);

  React.useEffect(() => {
    dispatch(setCssBreakpointName(breakpoint || null));
  }, [dispatch, breakpoint]);

  return (
    <>
      <ScrollLocker isLocked={scrollingLocked} />
      {children}
      <Footer />
      <CtaOverlayLazyLoaded visible={ctaOverlayIsVisible} />
      {/* <SiteLoader /> */}
    </>
  );
};
export default Layout;
