// re-written in node for gatsby-node interoperability

const joinUrl = require('./joinUrl');

const baseUrl = '/';

const mappers = {
  category: (slug, type, isClient) => (
    joinUrl([type, 'categories', slug], baseUrl, isClient)
  ),
  service: (slug, isClient) => (
    joinUrl(['services', slug], baseUrl, isClient)
  ),
  subService: (slug, parentSlug, isClient) => (
    joinUrl([mappers.service(''), parentSlug, slug], baseUrl, isClient)
  ),
  post: (slug, isClient, type = '') => (
    joinUrl(['blog', type, slug], baseUrl, isClient)
  ),
  industry: (slug, isClient) => (
    joinUrl(['industries', slug], baseUrl, isClient)
  ),
  industryService: (industrySlug, serviceSlug, isClient) => (
    joinUrl(['industries', industrySlug, serviceSlug], baseUrl, isClient)
  ),
  caseStudy: (slug, isClient) => (
    joinUrl(['case-studies', slug], baseUrl, isClient)
  ),
  contact: (slug, isClient) => (
    joinUrl(['contact', slug], baseUrl, isClient)
  ),
  about: (slug, isClient) => (
    joinUrl(['about', slug], baseUrl, isClient)
  ),
  blog: (slug, isClient) => (
    joinUrl(['blog', slug], baseUrl, isClient)
  ),
  career: (slug, isClient) => (
    joinUrl(['careers', slug], baseUrl, isClient)
  ),
  root: (slug, isClient) => (
    joinUrl([slug], baseUrl, isClient)
  ),
};

module.exports = mappers;
