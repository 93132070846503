import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.ContactSettingsSingleFragment | Record<string, never> => {
  const result = useStaticQuery<GatsbyTypes.ContactSettingsQuery>(graphql`
    fragment ContactSettingsSingle on WpContactSettings_Acf {
      companyName
      addressTopLine
      addressCity
      addressRegion
      addressPostCode
      addressCountry
      telephoneNumber
      twitterHandle
      instagramHandle
      linkedinHandle
    }
    query ContactSettings {
      allWp {
        nodes {
          contactSettings {
            acf {
              ...ContactSettingsSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].contactSettings?.acf || {};
};
