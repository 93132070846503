import React from 'react';
import { breakpointsOrdered, breakpointsBasic } from 'src/styles/theme';
import { BreakpointsBasic } from 'styled';

type KeysToBool<T> = { [Property in keyof T]: boolean };
type BreakpointTracker = KeysToBool<BreakpointsBasic>;
type ValidBreakpointKey = keyof typeof breakpointsBasic;
type ChangeHandler = (e: MediaQueryListEvent | MediaQueryList) => void;


const useBreakpoint = (): keyof BreakpointsBasic | undefined => {
  const [breakpointTracker, setBreakpointTracker] = React.useState<BreakpointTracker>(
    breakpointsOrdered.reduce((acc, point) => ({
      ...acc,
      [point.key]: false,
    }), {} as BreakpointTracker),
  );

  React.useEffect(() => {
    const mqls: Array<{
      handleChange: ChangeHandler,
      mql: MediaQueryList,
    }> = [];
    breakpointsOrdered.forEach(({ key, val }) => {
      const handleChange: ChangeHandler = (e) => {
        setBreakpointTracker((prevValue) => ({
          ...prevValue,
          [key]: e.matches,
        }));
      };

      const mql = window.matchMedia(`(min-width: ${val})`);
      if (typeof mql.addEventListener === 'function') mql.addEventListener('change', handleChange);
      else if (typeof mql.addListener === 'function') mql.addListener(handleChange);

      mqls.push({ handleChange, mql }); // for unmount cleanup
      handleChange(mql); // first run
    });

    return function unmount() {
      mqls.forEach(({ mql, handleChange: changeFunction }) => {
        mql.removeEventListener('change', changeFunction);
      });
    };
  }, []);
  let highestBreakpoint: ValidBreakpointKey = 'phone';
  for (let i = breakpointsOrdered.length - 1; i > 0; i -= 1) {
    const breakpoint = breakpointsOrdered[i];
    if (breakpointTracker[breakpoint.key]) {
      highestBreakpoint = breakpoint.key;
      break;
    }
  }

  return highestBreakpoint;
};

export default useBreakpoint;
