import React from 'react';
import styled, { css } from 'styled-components';
import {
  AllowedNumber,
  AllowedNumberWithNegatives,
  ShorthandMarginPadding,
  ShorthandMarginPaddingWithNegatives,
} from './types';
import { getShorthandSpacingVal, getSpacingVal } from './services';

interface SharedProps {
  className?: string,
  multiplier?: number,
  as?: string | React.FC | React.ComponentClass,
  p?: ShorthandMarginPadding,
  m?: ShorthandMarginPaddingWithNegatives,
  pt?: AllowedNumber,
  pr?: AllowedNumber,
  pb?: AllowedNumber,
  pl?: AllowedNumber,
  px?: AllowedNumber,
  py?: AllowedNumber,
  mt?: AllowedNumberWithNegatives,
  mr?: AllowedNumberWithNegatives,
  mb?: AllowedNumberWithNegatives,
  ml?: AllowedNumberWithNegatives,
  mx?: AllowedNumberWithNegatives,
  my?: AllowedNumberWithNegatives,
}

export type Props = SharedProps;

const Spacer:React.FC<SharedProps> = ({
  children,
  className,
  multiplier = 10,
  as: asProp,
  ...restProps
}) => {
  return (
    <Element
      className={className}
      as={asProp as any} // eslint-disable-line @typescript-eslint/no-unsafe-assignment
      multiplier={multiplier}
      {...restProps}
    >
      {children}
    </Element>
  );
};
export default Spacer;

const Element = styled.div<SharedProps & { multiplier: number }>`
  padding: ${(p) => getShorthandSpacingVal(p.multiplier, p.p)};
  margin: ${(p) => getShorthandSpacingVal(p.multiplier, p.m)};
  padding-top: ${(p) => getSpacingVal(p.multiplier, p.pt)};
  padding-right: ${(p) => getSpacingVal(p.multiplier, p.pr)};
  padding-bottom: ${(p) => getSpacingVal(p.multiplier, p.pb)};
  padding-left: ${(p) => getSpacingVal(p.multiplier, p.pl)};
  margin-top: ${(p) => getSpacingVal(p.multiplier, p.mt)};
  margin-right: ${(p) => getSpacingVal(p.multiplier, p.mr)};
  margin-bottom: ${(p) => getSpacingVal(p.multiplier, p.mb)};
  margin-left: ${(p) => getSpacingVal(p.multiplier, p.ml)};
  ${(p) => p.px && css`
    padding-left: ${getSpacingVal(p.multiplier, p.px)};
    padding-right: ${getSpacingVal(p.multiplier, p.px)};
  `};
  ${(p) => p.py && css`
    padding-top: ${getSpacingVal(p.multiplier, p.py)};
    padding-bottom: ${getSpacingVal(p.multiplier, p.py)};
  `};
  ${(p) => p.mx && css`
    margin-left: ${getSpacingVal(p.multiplier, p.mx)};
    margin-right: ${getSpacingVal(p.multiplier, p.mx)};
  `};
  ${(p) => p.my && css`
    margin-top: ${getSpacingVal(p.multiplier, p.my)};
    margin-bottom: ${getSpacingVal(p.multiplier, p.my)};
  `};
`;
