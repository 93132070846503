import React from 'react';
import { WrapPageElementNodeArgs } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import theme from 'src/styles/theme';
import { Provider } from 'react-redux';
import { store } from 'store';
import 'normalize.css';

const WrapRootElement = ({ element }: WrapPageElementNodeArgs): React.ReactNode => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    </Provider>
  );
};
export default WrapRootElement;
