export default (
  input: number,
  low = 0,
  high = 1,
): number => (
  Math.min(
    Math.max(input, low),
    high,
  )
);
