import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import useContactSettings from 'hooks/queries/useContactSettings';
import SmallIcon from 'components/SmallIcon';
import {
  faTwitter,
  faLinkedin,
  faInstagram,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import Button from 'components/Button';

interface SocialType {
  icon: IconDefinition,
  name: string,
  url: string,
}

export interface Props {
  type: 'instagram' | 'twitter' | 'linkedIn',
  color?: React.CSSProperties['color'],
}

const SocialButton:React.FC<Props> = ({
  type,
  color,
}) => {
  const theme = useContext(ThemeContext);
  const {
    twitterHandle = '',
    linkedinHandle = '',
    instagramHandle = '',
  } = useContactSettings();

  const socialTypes: { [key in Props['type']]: SocialType } = {
    twitter: {
      icon: faTwitter,
      name: 'Twitter',
      url: `https://twitter.com/${twitterHandle}`,
    },
    instagram: {
      icon: faInstagram,
      name: 'Instagram',
      url: `https://www.instagram.com/${instagramHandle}/`,
    },
    linkedIn: {
      icon: faLinkedin,
      name: 'LinkedIn',
      url: `https://www.linkedin.com/company/${linkedinHandle}/`,
    },
  } as const;

  const activeSocialType = socialTypes[type];

  return (
    <SocialButtonOuter title={activeSocialType.name}>
      <Button
        href={activeSocialType.url}
        borderless
        target="_blank"
        background="transparent"
        type="clear"
      >
        <SmallIcon
          icon={activeSocialType.icon}
          borderColor={color || theme.palette.primary}
          color={color}
        />
      </Button>
    </SocialButtonOuter>
  );
};
export default SocialButton;

const SocialButtonOuter = styled.div``;
