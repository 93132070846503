import React from 'react';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { Variants } from './types';

interface ElementProps {
  bold?: true,
  italic?: true,
  $color?: React.CSSProperties['color'],
  $maxWidth?: React.CSSProperties['maxWidth'],
}

// const hyphensCss = css`
//   -webkit-hyphens: auto;
//   hyphens: auto;
//   word-break: break-word;
// `;

const headingCss = css`
  line-height: 1.2em;
  font-weight: bold;
  font-family: ${(p) => p.theme.type.font2};
  color: ${(p) => p.theme.palette.primary};
  .dark-bg & {
    color: ${(p) => p.theme.palette.secondary};
  }
`;

const baseCss = css<ElementProps>`
  /* margin: 0; */
  color: ${(p) => (p.$color ? `${p.$color} !important` : undefined)};
  max-width: ${(p) => p.$maxWidth};
  line-height: 1.4em;
  ${(p) => p.bold && css`
    font-weight: bold;
  `};
  ${(p) => p.italic && css`
    font-style: italic;
  `};
`;

const marginZeroCss = css`
  margin: 0;
`;

const basicParagraphSpacingCss = css`
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;

export const variantComponents: Record<Variants, AnyStyledComponent> = {
  pageTitle: styled.h1`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    font-size: 4rem;
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 7rem;
    }
    ${({ theme }) => theme.breakpoints.up('tabletWide')} {
      font-size: 9rem;
    }
  `,
  pageTitleSmaller: styled.h1`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    font-size: 4rem;
    line-height: 1em;
    ${({ theme }) => theme.breakpoints.up('phone')} {
      font-size: 5rem;
    }
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 6rem;
    }
    ${({ theme }) => theme.breakpoints.up('tabletWide')} {
      font-size: 7rem;
    }
    ${({ theme }) => theme.breakpoints.up('desktop')} {
      font-size: 9rem;
    }
  `,
  pageSubTitle: styled.h2`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
  `,
  h1: styled.h1`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 4.77rem;
    }
  `,
  h2: styled.h2`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 3.98rem;
    }
  `,
  h3: styled.h3`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 3.31rem;
    }
  `,
  h4: styled.h4`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 2.76rem;
    }
  `,
  h5: styled.h5`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 2.3rem;
    }
  `,
  h6: styled.h6`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 1.92rem;
    }
  `,
  body: styled.p`
    ${baseCss};
    ${marginZeroCss};
  `,
  body2: styled.p`
    ${baseCss};
    ${marginZeroCss};
  `,
  bodyPullout: styled.p`
    ${baseCss};
    ${marginZeroCss};
    ${({ theme }) => theme.breakpoints.up('phoneWide')} {
      font-size: 1.5em;
    }
  `,
  hero: styled.p`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
    font-size: 4rem;
    line-height: 1.1em;
    /* font-weight: normal; */
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      font-size: 8rem;
    }
    ${({ theme }) => theme.breakpoints.up('tabletWide')} {
      font-size: 12rem;
    }
  `,
  hero2: styled.p`
    ${baseCss};
    ${marginZeroCss};
    ${headingCss};
  `,
  small: styled.p`
    ${baseCss};
    ${marginZeroCss};
    font-size: 1.2rem;
  `,
  smallPrint: styled.p`
    ${baseCss};
    ${marginZeroCss};
    font-size: 0.8rem;
  `,
  container: styled.div`
    ${baseCss};
    ${basicParagraphSpacingCss};
  `,
};
