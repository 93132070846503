import { DefaultTheme } from 'styled-components';
import breakpoints from './breakpoints';

export * from './breakpoints';


const misc: DefaultTheme['misc'] = {
  pageTransitionDuration: 300,
  readibleWidth: '44em',
  indentShiftAmount: '100px',
};

const palette: DefaultTheme['palette'] = {
  primary: '#00013A',
  secondary: '#41f0ff',
  tertiary: 'red',
  quaternary: '#1A1A4E',
  text: '#333',
  darkBgText: 'white',
  interactive: '#FF9E1A',
};

const type: DefaultTheme['type'] = {
  // font1: 'DM Sans, sans-serif',
  font1: 'Poppins, sans-serif',
  font2: 'Poppins, sans-serif',
};


const theme: DefaultTheme = {
  misc,
  palette,
  type,
  breakpoints,
};

export default theme;
