type UnknownObject = Record<string, unknown>;

function isArrayOfObjects(input: any): input is UnknownObject[] {
  if (!Array.isArray(input)) return false;
  if (!input.every((item) => typeof item === 'object')) return false;
  return true;
}

export default (): UnknownObject[] => {
  const theWindow = window as unknown as UnknownObject;
  if (isArrayOfObjects(theWindow?.dataLayer)) {
    return theWindow.dataLayer;
  }
  return [];
};
