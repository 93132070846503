import { ContactSettings } from 'types';
import useContactSettings from 'hooks/queries/useContactSettings';
import useFooterSettings from 'hooks/queries/useFooterSettings';
import useGeneralSettings from 'hooks/queries/useGeneralSettings';
import { nanoid } from 'nanoid';
import { resolveUrl } from 'helpers';

interface Link {
  id: string,
  slug: string,
  title: string,
}

interface UseFooterConnector {
  siteName: string,
  tagLine: string,
  contactSettings: ContactSettings,
  smallPrintLinks: Link[],
  quickNavLinks: Link[],
}

export default (): UseFooterConnector => {
  const contactSettingsQuery = useContactSettings();
  const footerSettingsQuery = useFooterSettings();
  const generalSettingsQuery = useGeneralSettings();

  const smallPrintLinks = footerSettingsQuery.smallprintPages?.map((link) => ({
    id: nanoid(),
    slug: resolveUrl.root(link?.slug || ''),
    title: link?.title || '',
  })) || [];

  const quickNavLinks = footerSettingsQuery.quicknavLinks?.map<Link>((item) => ({
    id: nanoid(),
    slug: item?.quicknavLink?.url || '',
    title: item?.quicknavLink?.title || '',
  })) || [];

  const contactSettings: UseFooterConnector['contactSettings'] = {
    companyName: contactSettingsQuery.companyName || '',
    addressTopLine: contactSettingsQuery.addressTopLine || '',
    addressCity: contactSettingsQuery.addressCity || '',
    addressRegion: contactSettingsQuery.addressRegion || '',
    addressPostCode: contactSettingsQuery.addressPostCode || '',
    addressCountry: contactSettingsQuery.addressCountry || '',
    telephoneNumber: contactSettingsQuery.telephoneNumber || '',
    twitterHandle: contactSettingsQuery.twitterHandle || '',
    instagramHandle: contactSettingsQuery.instagramHandle || '',
    linkedinHandle: contactSettingsQuery.linkedinHandle || '',
  };

  return {
    siteName: generalSettingsQuery.title || '',
    tagLine: generalSettingsQuery.description || '',
    smallPrintLinks,
    quickNavLinks,
    contactSettings,
  };
};
