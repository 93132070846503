/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { buttonCss } from 'src/components/Button';
import { css } from 'styled-components';

const wrapCss = css`
  .wp-block-buttons {
    display: flex;
    margin: 0 -5px;
    .wp-block-button {
      padding: 5px;
    }
  }
`;

export default css`
  ${wrapCss};
  .wp-block-button {
    .wp-block-button__link {
      ${buttonCss}
      border-color: ${(p) => p.theme.palette.interactive};
    }
    &:not(.is-style-outline) {
      .wp-block-button__link {
        background: ${(p) => p.theme.palette.interactive};
        color: white;
      }
    }
  }
`;
