import {
  AllowedNumber,
  AllowedNumberWithNegatives,
  ShorthandMarginPadding,
  ShorthandMarginPaddingWithNegatives,
} from './types';

export function getSpacingVal(
  multiplier: number,
  input?: AllowedNumber | AllowedNumberWithNegatives,
): string | undefined {
  if (typeof input === 'undefined') return undefined;
  return `${input * multiplier}px`;
}

export function getShorthandSpacingVal(
  multiplier: number,
  input?: ShorthandMarginPadding | ShorthandMarginPaddingWithNegatives,
): string | undefined {
  if (typeof input === 'undefined') return undefined;
  const arraySafe = Array.isArray(input) ? input : [input];
  return arraySafe.map((val) => (
    getSpacingVal(multiplier, val)
  )).join(' ');
}
