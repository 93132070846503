import { css } from 'styled-components';
import theme from '../theme';

export default css`
  blockquote {
    p {
      &:before, &:after {
        font-weight: bold;
        opacity: 0.8;
      }
      &:first-of-type:before {
        content: '“';
        position: absolute;
        transform: translate(-0.5em, -0.4em);
      }
      &:last-of-type:after {
        content: '”';
      }
    }
    cite {
      font-weight: bold;
    }
  }
  figure.wp-block-pullquote {
    margin: 3em 2em;
    blockquote {
      margin: 0;
      padding-left: 1.5em;
      border-left: 2px solid ${theme.palette.tertiary};
      p {
        font-weight: bold;
      }
      cite {
        display: inline-block;
        margin-top: 1em;
      }
    }
  }
  blockquote.wp-block-quote {
    margin: 0;
    padding: 0em 2em 1em;
    p {
      font-weight: inherit;
      margin-top: 0;
      margin-bottom: 0.5em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    cite {
      display: inline-block;
      margin-top: 0.5em;
      /* margin-left: 1em; */
    }
  }
`;
