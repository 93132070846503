/**
 * intended for use in omitting inline falsy array literal items
 * e.g.
 * const cleanArray = [
 *   val,
 *   ...conditionalItem(val),
 * ];
 */
export default (input: unknown): string[] => {
  if (typeof input === 'string' && input.length) return [input];
  return [];
};
