import { css } from 'styled-components';
import theme from '../theme';

const columns = Array.from(Array(5).keys());

export default css`
  .wp-block-gallery {
    ${theme.breakpoints.up('tablet')} {
      ${columns.map((number) => css`
        &.columns-${number} {
          li.blocks-gallery-item {
            width: ${100 / number}%;
            flex-basis: ${100 / number}%;
          }
        }
      `)};
    }
    ul.blocks-gallery-grid {
      margin: 0 -10px;
      padding: 0;
      list-style-type: none;
      ${theme.breakpoints.up('tablet')} {
        display: flex;
        flex-wrap: wrap;
      }
      li.blocks-gallery-item {
        box-sizing: border-box;
        padding: 10px;
        figure { margin: 0; }
        ${theme.breakpoints.up('tablet')} {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }
`;
