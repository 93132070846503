import { configureStore } from '@reduxjs/toolkit';
import ctaOverlay from './features/ctaOverlay/ctaOverlaySlice';
import navMain from './features/nav/main/navMainSlice';
import utility from './features/utility/utilitySlice';

export const store = configureStore({
  reducer: {
    ctaOverlay,
    navMain,
    utility,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
