import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg';

export interface Props {}

const Logo:React.FC<Props> = () => {
  return (
    <StyledSvg viewBox="0 0 85 84.2">
      <LogoGroup>
        <Path d="M32.9,41.6c0,5.3,4.3,9.6,9.6,9.6l0,0c5.3,0,9.6-4.3,9.6-9.6S47.8,32,42.5,32C37.2,32,32.9,36.3,32.9,41.6z" />
        <Path d="M73,12.5C65.3,4.7,55.1,0.3,44.1,0h-0.2c-0.5,0-1,0-1.4,0h-9.6v8.8C50.5,3.7,69.2,13.4,75,31c5.9,17.9-3.9,37.2-21.8,43.1 C35.2,80,15.9,70.2,10,52.3c-1.1-3.4-1.7-7-1.7-10.6V0H0v41.7c0,23.4,19.1,42.5,42.5,42.5c0,0,0,0,0,0C66,84.2,85,65.1,85,41.6 C84.9,30.7,80.7,20.3,73,12.5z" />
        <Path d="M42.5,67.6C42.6,67.6,42.6,67.6,42.5,67.6L42.5,67.6C42.6,67.6,42.6,67.6,42.5,67.6c12.9,0,23.8-9.3,25.8-21.9h-8.5 c-1.1,4.4-3.7,8.1-7.5,10.6c-4,2.6-8.7,3.5-13.3,2.6c-9.6-2-15.8-11.4-13.8-21c1-4.6,3.7-8.6,7.6-11.2c4-2.6,8.7-3.5,13.3-2.6 c6.9,1.4,12.2,6.7,13.7,13.5h8.5c-2.1-13.8-14.8-23.6-28.8-22c-4.6,0.5-8.9,2.3-12.6,5l-2,1.5l-0.2-2.4V0h-8.3v41.6 C16.5,55.9,28.2,67.6,42.5,67.6z" />
      </LogoGroup>
    </StyledSvg>
  );
};
export default Logo;

const LogoGroup = styled.g``;
const Path = styled.path`
  fill: ${(p) => p.theme.palette.secondary};
`;
const StyledSvg = styled(Svg)`
  width: 100%;
  fill: ${(p) => p.theme.palette.primary};
  .dark-bg & {
    fill: ${(p) => p.theme.palette.darkBgText};
  }  
`;
