import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg';

export interface Props {}

const Wordmark:React.FC<Props> = () => {
  return (
    <StyledSvg viewBox="0 0 300 45.4">
      <Blueclaw>
        <title>Blueclaw</title>
        <Path d="M45.7,0.9h4.2v44.5h-4.2V0.9z" />
        <Path d="M62.8,12.5h4.2v15c0,3.7,0.2,6.2,0.6,7.6c0.6,2,1.7,3.5,3.4,4.7c1.7,1.1,3.7,1.7,6,1.7c2.3,0,4.3-0.6,5.9-1.7 c1.6-1.1,2.7-2.6,3.3-4.4c0.4-1.2,0.6-3.9,0.6-7.9v-15h4.2v15.8c0,4.4-0.5,7.8-1.6,10c-1,2.2-2.6,4-4.7,5.2 c-2.1,1.3-4.7,1.9-7.8,1.9c-3.1,0-5.8-0.6-7.8-1.9s-3.7-3-4.7-5.3c-1-2.3-1.5-5.7-1.5-10.3L62.8,12.5L62.8,12.5z" />
        <Path d="M131.9,34l3.5,1.8c-1.1,2.2-2.4,4.1-3.9,5.4c-1.5,1.4-3.2,2.4-5.1,3.1c-1.9,0.7-4,1.1-6.3,1.1 c-5.2,0-9.3-1.7-12.3-5.1c-2.9-3.4-4.4-7.3-4.4-11.6c0-4.1,1.2-7.7,3.7-10.9c3.2-4.1,7.4-6.1,12.7-6.1c5.4,0,9.8,2.1,13.1,6.2 c2.3,2.9,3.5,6.6,3.5,10.9h-28.8c0.1,3.7,1.3,6.8,3.6,9.2c2.3,2.4,5.1,3.6,8.5,3.6c1.6,0,3.2-0.3,4.8-0.9s2.9-1.3,3.9-2.3 C129.4,37.6,130.6,36.1,131.9,34z M131.9,25.3c-0.5-2.2-1.3-4-2.4-5.3c-1.1-1.3-2.5-2.4-4.2-3.2c-1.7-0.8-3.6-1.2-5.5-1.2 c-3.1,0-5.8,1-8.1,3c-1.6,1.5-2.9,3.7-3.7,6.7L131.9,25.3L131.9,25.3z" />
        <Path d="M180.1,19.2l-3.3,2.1c-2.8-3.8-6.7-5.7-11.6-5.7c-3.9,0-7.2,1.3-9.8,3.8s-3.9,5.6-3.9,9.2 c0,2.3,0.6,4.6,1.8,6.6c1.2,2.1,2.8,3.7,4.9,4.8s4.4,1.7,7,1.7c4.7,0,8.6-1.9,11.6-5.6l3.3,2.1c-1.5,2.3-3.6,4.1-6.2,5.3 s-5.5,1.9-8.9,1.9c-5.1,0-9.3-1.6-12.6-4.8c-3.4-3.2-5-7.1-5-11.7c0-3.1,0.8-6,2.4-8.7s3.7-4.7,6.4-6.2c2.7-1.5,5.8-2.2,9.2-2.2 c2.1,0,4.2,0.3,6.1,1s3.7,1.5,5.1,2.5C178,16.2,179.1,17.6,180.1,19.2z" />
        <Path d="M192.2,0.9h4.2v44.5h-4.2V0.9z" />
        <Path d="M250.3,13.3h4.2l9.8,23.3l10.3-23.3h0.7l10.3,23.3l10-23.3h4.3l-13.9,32.1h-0.8l-10.2-23l-10.3,23H264 L250.3,13.3z" />
        <Path d="M0,0h4.1v19.2c1.3-2.1,3.1-3.8,5.2-5 c2.2-1.2,4.6-1.9,7.2-1.9c2.9,0,5.8,0.7,8.3,2.2c2.5,1.4,4.6,3.5,6,6c3,5.1,3,11.5,0,16.6c-1.4,2.5-3.5,4.6-6,6 c-5.1,2.9-11.4,2.9-16.6,0C3.1,40.2,0,34.8,0,28.9L0,0z M10.2,17.9c-1.9,1.1-3.4,2.7-4.5,4.6c-2.2,4-2.2,8.8,0,12.8 c1,1.9,2.6,3.5,4.5,4.6c3.9,2.2,8.7,2.2,12.7,0c1.9-1.1,3.4-2.7,4.5-4.6c1.1-2,1.7-4.2,1.6-6.4c0-4.5-2.3-8.7-6.2-10.9 c-1.9-1.1-4.1-1.7-6.3-1.7C14.3,16.2,12.1,16.8,10.2,17.9z" />
        <Path d="M216.2,43.2c-2.5-1.4-4.6-3.5-6-6 c-3-5.1-3-11.5,0-16.6c1.4-2.5,3.5-4.6,6-6c5.1-2.9,11.4-2.9,16.6,0c5.1,3,8.2,8.4,8.2,14.3v16h-4.1V38c-1.1,2.2-2.9,4.1-5,5.3 c-2.2,1.4-4.8,2.1-7.4,2.1C221.6,45.4,218.7,44.7,216.2,43.2z M230.8,39.8c1.9-1.1,3.4-2.7,4.5-4.6c2.2-4,2.2-8.8,0-12.8 c-2.2-3.9-6.3-6.3-10.7-6.3c-4.5-0.1-8.6,2.4-10.8,6.3c-1.1,1.9-1.7,4.1-1.7,6.4c0,4.5,2.3,8.7,6.2,10.9 C222.2,42.1,226.9,42.1,230.8,39.8z" />
      </Blueclaw>
    </StyledSvg>
  );
};
export default Wordmark;

const Blueclaw = styled.g``;
const Path = styled.path``;
const StyledSvg = styled(Svg)`
  width: 100%;
  fill: ${(p) => p.theme.palette.primary};
  .dark-bg & {
    fill: ${(p) => p.theme.palette.darkBgText};
  }  
`;
