import { css } from 'styled-components';
// import theme from '../theme';

export default css`
  img {
    max-width: 100%;
    height: auto;
  }
  figure.wp-block-image {
    margin-bottom: 2em;
    &.is-style-rounded {
      > span {
        border-radius: 50%;
      }
    }
    figcaption {
      font-style: italic;
    }
  }
`;
