/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Variants } from 'src/components/Typography/types';
import { variantComponents } from 'src/components/Typography/variantComponents';
import { css } from 'styled-components';

// to allow finer control of header margins if needed
const headingsMarginMultipliers = {
  h1: 1,
  h2: 1,
  h3: 1,
  h4: 1,
  h5: 1,
  h6: 1,
};

export default css`
  ${['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((heading) => css`
    ${heading} {
      ${variantComponents[heading as Variants]?.componentStyle?.rules};
    }
  `)};

  ${Object.entries(headingsMarginMultipliers).map(([heading, multiplier]) => css`
    ${heading} {
      margin-top: ${1 * multiplier}em;
      margin-bottom: ${0.5 * multiplier}em;
    }
  `)};

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }
`;
