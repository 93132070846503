/* eslint-disable prefer-template */
import React from 'react';
import Layout from 'components/Layout';
import {
  WrapPageElementNodeArgs,
  // PageProps,
} from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyle from 'src/styles/globals';

/*
  works as-is, but could be tightened up
  this is also run in the browser context where typings will be slightly different
  also edge cases potentially may not produce an 'element'..?
 */
interface Params extends WrapPageElementNodeArgs {}

const WrapPageElement = (params: Params):React.ReactNode => {
  const { element, props } = params;
  // const { props } = element;
  // const { location: { pathname } } = props;

  let pathname = props?.location?.pathname;
  if (pathname === '/') pathname = '';
  const siteUrl = process.env.GATSBY_SITE_URL;
  if (!siteUrl) throw new Error('Missing site url, terminating process...');
  const conditionalTrailingSlash = pathname ? '/' : '';
  // not exactly sure why this seems to be required...
  const GlobalStyleTypeHack = GlobalStyle as unknown as React.ComponentType;

  return (
    <PageWrap>
      <GlobalStyleTypeHack />
      <Helmet>
        <link rel="canonical" href={siteUrl + pathname + conditionalTrailingSlash} />
      </Helmet>
      <Layout>{element}</Layout>
    </PageWrap>
  );
};
export default WrapPageElement;

const PageWrap = styled.div``;
