import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import CompanyLogo from 'components/CompanyLogo';
import Typography from 'components/Typography';
import Block from 'components/Block';
import Spacer from 'components/Spacer';
import SocialButton from 'components/SocialButton';
import { tagManagerDataLayerEventNames } from 'src/helpers';
import { getDataLayer } from 'src/lib/helpers';
import TelephoneNumber from 'src/helpers/TelephoneNumber';
import useConnector from './useConnector';
// import SafeHtml from 'lib/components/SafeHtml';

export interface Props {}

const Footer:React.FC<Props> = () => {
  const {
    tagLine,
    smallPrintLinks,
    quickNavLinks,
    contactSettings: {
      companyName,
      addressTopLine,
      addressCity,
      addressRegion,
      addressPostCode,
      addressCountry,
      telephoneNumber,
    },
  } = useConnector();

  const currentYear = new Date().getFullYear();
  const {
    international,
    internationalFormatted,
  } = new TelephoneNumber(String(telephoneNumber), true);

  const handleTelephoneNumberClick = (): void => {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: tagManagerDataLayerEventNames.TELEPHONE_CTA_CLICK,
    });
  };

  return (
    <FooterOuter>
      <Block darkBg contain>
        <CompanyLogo withHomeLink />
        <Spacer mb={2} />
        <Typography maxWidth="25em">{tagLine}</Typography>
        <Spacer mb={4} />

        <Row mx={-3} mb={1}>
          {quickNavLinks.length > 1 && (
            <Col px={3}>
              <Typography variant="h3">Links:</Typography>
              <Spacer mb={1} />
              {quickNavLinks.map((link) => (
                <Typography key={link.id} variant="body">
                  <a href={link.slug}>{link.title}</a>
                </Typography>
              ))}
              <Spacer mb={2} />
            </Col>
          )}
          <Col px={3}>
            <Typography variant="h3">Find Us:</Typography>
            <Spacer mb={1} />
            <Typography variant="container">
              <Typography variant="body">{companyName}</Typography>
              <Typography variant="body">{addressTopLine}</Typography>
              <Typography variant="body">{addressCity}</Typography>
              <Typography variant="body">{addressRegion}</Typography>
              <Typography variant="body">{addressPostCode}</Typography>
              <Typography variant="body">{addressCountry}</Typography>
            </Typography>
            <Spacer mb={2} />
          </Col>
          <Col px={3}>
            <Typography variant="h3">Call Us:</Typography>
            <Spacer mb={1} />
            <Typography variant="body">
              <a
                href={`tel:${international}`}
                onClick={handleTelephoneNumberClick}
              >
                {internationalFormatted}
              </a>
            </Typography>
            <Spacer mb={2} />
          </Col>
        </Row>

        <SocialButtons>
          <SocialButton type="twitter" />
          <SocialButton type="instagram" />
          <SocialButton type="linkedIn" />
        </SocialButtons>
        <Spacer mb={4} />

        <Typography variant="smallPrint">
          <Typography as="span">© {companyName} {currentYear}</Typography>
          {smallPrintLinks.length ? (
            <>
              <LinkSeparator />
              <FooterLinks>
                {smallPrintLinks.map((link, i) => (
                  <React.Fragment key={link.id}>
                    <LinkStyled to={link.slug}>{link.title}</LinkStyled>
                    {i < smallPrintLinks.length - 1 && <LinkSeparator />}
                  </React.Fragment>
                ))}
              </FooterLinks>
            </>
          ) : null }
        </Typography>

      </Block>
    </FooterOuter>
  );
};
export default Footer;

const FooterOuter = styled.section`
  overflow: hidden;
`;
const Row = styled(Spacer)`
  ${({ theme }) => theme.breakpoints.up('tabletSmall')} {
    display: flex;
  }
`;
const Col = styled(Spacer)``;
const SocialButtons = styled.div`
  display: flex;
`;
const FooterLinks = styled.span``;
const anchorLinkCss = css`
  display: inline-block;
  text-decoration: none;
  color: inherit;
`;
const LinkStyled = styled(Link)`
  ${anchorLinkCss};
`;
const AnchorStyled = styled.a`
  ${anchorLinkCss};
`;
const LinkSeparator = styled.span.attrs(() => ({ children: ' • ' }))``;
